.layout {
	height: 100vh;
	.container {
		max-width: 900px;
		margin: 0 auto;
		height: 100%;
		padding: 0 15px;

	}
	.main {
		height: calc(100vh - 80px - 80px);
		min-height: 760px;
		position: relative;
		.container {
			min-height: 760px;
			height: calc(100vh - 80px - 80px);
			position: relative;
			display: flex;
			align-items: stretch;
		}	
	}
}

@media (max-height: 920px) {
	.layout {
		height: auto;
		min-height: 100vh;
		.main {
			height: auto;
			min-height: calc(100vh - 80px - 80px);
			.container {
				min-height: calc(100vh - 80px - 80px);
				height: auto;
			}
		}
	}
}
