.Summary {
  padding-top: 40px;
	padding-bottom: 40px;
  text-align: center;
	flex-grow: 1;
	display: flex;
	align-items: center;
	flex-direction: column;



  .invitation {
    margin-bottom: 16px;
  }

  .logo {
    width: 130px; 
    margin-bottom: 16px;
  }

	button {
		max-width: 180px;
		&:hover {
			background-color: #ddd3d3;
		}
	}

}


@media (max-height: 800px) {
	.Summary {
		.logo {
			width: 80px; 
			margin-bottom: 11px;
		}


	}
}


