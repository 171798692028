.Drawer {
  position: fixed;
  left: 0;
  top: 80px;
  bottom: 0;
  width: 100%;
  padding: 20px 10px;
  background: rgba(0, 0, 0, .9);
  box-sizing: border-box;
  transform: translateX(0px);
  transition: transform .22s ease-in;
  z-index: 50;
}


.Drawer.closed {
  transform: translateX(-100%);
}

.Drawer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Drawer ul li {
  margin-bottom: 15px;
}

.Drawer ul li a {
  color: #ffffff;
  text-decoration: none;
  position: relative;
  padding: 0 20px 10px 0;
  transition: opacity .3s;
  font-family: Gotham;
  font-weight: 500;
  font-size: 20px;
}

.Drawer ul li a:hover, .active {
  opacity: .7;
}
