.Analytics {
	flex-grow: 1;
	padding: 40px 0;
	width: 100%;
	min-height: calc(100vh - 80px - 80px);
	position: relative;

	h2 {
    margin-bottom: 16px;
  }


	.analytics-wrap {
		margin-bottom: 24px;
	}

	.Select-hidden {
		position: absolute;
		top: 40px;
		right: 0;
		padding-bottom: 20px;
		span {
			.arrow {
				position: relative;
				top: 12px;
				left: 4px;
				width: 0; 
				height: 0; 
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid #160f09;	
				border-bottom: none;
			}
		}

		.select-wrapper {
			position: absolute;
			bottom:0;
			right:0; 
			display: none;
		}
		&:hover {
			.select-wrapper {
				display: block;
				top:0px;
				width: 200px;
				padding-top: 16px;
			}
		}
	}
}	

@media (max-width: 500px) {
	.Analytics {
		h2 {
			font-size: 20px;
			text-align: left;
		}

		.Select-hidden {
			right: 15px;
		}
	}
}