.SummaryItem {
  margin-bottom: 24px;
  h2 {
    margin-bottom: 8px;
  }
  .data {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .comment {
    font-size: 16px;
    white-space: pre-line;
		margin-bottom: 4px;
  }
}

@media (max-height: 800px) {
  .SummaryItem {
    margin-bottom: 16px;
    .data {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 4px;
    }
    .comment {
      margin-bottom: 0;
    }    
  }

}
