.navbar {
  background-color: #160f09;
  width: 100%;
  height: 80px;
}

.navbar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar.leads-page .navbar-wrap,
.navbar.analytics-page .navbar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .logo-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%; 
		.mama-logo {
			width: 80px;
		}
    a {
      margin-right: 20px;
    }
  }
}


.link {
  font-family: Gotham;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.01em;
  color: #ffffff;
  text-decoration: none;
}

.mama-logo {
  width: 120px;
}

.toggle-btn {
  width: 160px;
  display: flex;
  justify-content: flex-end;
}

.hamburger-react {
  div {
    background-color: #ffffff !important;
  }
}

@media (max-width: 400px) {
  .link {
    font-size: 16px;
  }

  .mama-logo {
    width: 100px;
    margin-left: 20px;
  }
}


