.socials {
  width: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-icon {
  height: 25px;
}
